let version = 'Word';
const coreUrl = "https://ai.alexatranslations.com/v2/";
Office.onReady(function (info) {
    $(document).ready(function () {
        $('.js-example-basic-single').select2({
            minimumResultsForSearch: -1,
            selectionCssClass: 'selectorCustom'
        });
    });

    if (info.host === Office.HostType.Word) {
        version = 'Word';
    } else if (info.host === Office.HostType.Excel) {
        version = 'Excel';
    } else if (info.host === Office.HostType.PowerPoint) {
        version = 'PowerPoint';
    } else if (info.host === Office.HostType.Outlook) {
        version = 'Outlook';
        referenceSection.style.display = "none";
        buttonsResultsData.style.display = "none";
        loginOfflineButton.style.display = "none";
        welcomingMessageSection.style.display = "none";
        // Hidden until further update
        // wordExcelPowerpointTranslate.style.display = "none";
        // outlookTranslate.style.display = "block";
    }
});

const closeBtn = document.getElementById("closeBtn");
const closeBtnReject = document.getElementById("closeBtnReject");
const closeBtnRejectClientId = document.getElementById("closeBtnRejectClientId");
const popupApprove = document.getElementById("popupApprove");
const popupReject = document.getElementById("popupReject");
const popupClientIdReject = document.getElementById("popupClientIdReject");
const clientIdRejectSpan = document.getElementById("clientIdRejectSpan");
let currentText = "";
const buttonList = ["Option1", "Option2", "Option3", "Option4"];
// const fileToRead = document.getElementById("#fileupload") as HTMLInputElement | null;
const onlineStep = document.getElementById("onlineStep");
const keyCheckStep = document.getElementById("keyCheckStep");
const introStepOne = document.getElementById("introStepOne");
const introStepTwo = document.getElementById("introStepTwo");
const fromLanguageDropdown = document.getElementById("fromLanguageDropdown");
const toLanguageDropdown = document.getElementById("toLanguageDropdown");
const toLanguageDropdownOutlook = document.getElementById("toLanguageDropdownOutlook");
// NEW UI
const resultsSection = document.getElementById("resultsSection");
const resultsSectionHeader = document.getElementById("resultsSectionHeader");
const noTextSelected = document.getElementById("noTextSelected");
const noOptionSelected = document.getElementById("noOptionSelected");
const arrowSwitch = document.getElementById("arrowSwitch");
const logoutAnchor = document.getElementById("logoutAnchor");

let languagesArray = [
    { name: "English", language: "eng" },
    { name: "French", language: "fra" },
    { name: "Spanish", language: "spa" },
    { name: "Chinese Simplified", language: "zhs" },
    { name: "Portuguese", language: "por" },
    { name: "Italian", language: "ira" },
    { name: "German", language: "deu" },
    { name: "Japanese", language: "jpn" },
    { name: "Korean", language: "kor" },
    { name: "Arabic", language: "ara" },
];
let languagesObject = {
    "en-ca": "English",
    "fr-ca": "French"
}
let fromLanguageDropdownSelectedValue = "eng";
let toLanguageDropdownSelectedValue = "fra";
let clientIdValue = "";
let clientUsernameValue = "";
let clientProjectIdValue = "";
var int = null;
let fileUploaded = false;
(function () {
    onlineStep.style.display = "none";
    logoutAnchor.style.display = "none";
    const cId = localStorage.getItem(version + "cId");
    if (!!cId) {
        clientIdValue = cId;
        checkForExistingClientId(localStorage.getItem(version + "cId"), false);
    }
})();
let isOnlineLogin = false;
let currentRange;
closeBtn.addEventListener("click", () => {
    popupClose();
});
closeBtnReject.addEventListener("click", () => {
    popupReject.style.display = "none";
});
closeBtnRejectClientId.addEventListener("click", () => {
    popupClientIdReject.style.display = "none";
    clientIdRejectSpan.style.display = "none";
});
$("#OpenFileUpload").click(function ($event) {
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    $("#fileupload").trigger("click");
});

$("#closenoTextSelected").click(function ($event) {
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    noTextSelected.style.display = "none";
});
$("#closenoOptionSelected").click(function ($event) {
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    noOptionSelected.style.display = "none";
});

// TOOLTIP FUNCTIONS
function updateToolTipTexts(state) {
    if (state) {
        // OFFLINE
        const ttText = "<div><h6 style='font-size: 12px;margin: 0;'>" +
            "REFERENCE DOCUMENT</h6><p>A reference document would help you have more consistency in the translation results.</p></div>"
        document.getElementById("uploadToolTipText").innerHTML = ttText;
        document.getElementById("uploadToolTipTextUploaded").innerHTML = ttText;
        document.getElementById("uploadToolTipTextInfo").innerHTML = "<div><h6 style='font-size: 12px;margin: 0;'>" +
            "TRANSLATE SELECTED TEXT</h6><p>Please upload the document and the languages will be loaded.</p></div>";
    } else {
        // ONLINE
        const ttTextt = "<div><h6 style='font-size: 12px;margin: 0;'>" +
            "OFFLINE USAGE</h6><p>A reference document would help you have more consistency in the translation results.</p></div>";
        document.getElementById("uploadToolTipText").innerHTML = ttTextt;
        document.getElementById("uploadToolTipTextUploaded").innerHTML = ttTextt;
        document.getElementById("uploadToolTipTextInfo").innerHTML = "<div><h6 style='font-size: 12px;margin: 0;'>" +
            "TRANSLATE SELECTED TEXT</h6><p>Please select the desired languages, then highlight the text and click \"Translate\".</p></div>";

    }
}

function createDropdownLists() {
    urlCallGet(`${coreUrl}languages/?credential=${clientIdValue}`).then((response) => {
        languagesArray = response;
        fromLanguageDropdown.options.length = 0;
        languagesArray.forEach((language) => {
            let opt = document.createElement("option");
            opt.text = language.name;
            opt.value = language.language;
            fromLanguageDropdown.options.add(opt);
        });
        swap(languagesArray, 0, 1);
        toLanguageDropdown.options.length = 0;
        languagesArray.forEach((language) => {
            let opt = document.createElement("option");
            opt.text = language.name;
            opt.value = language.language;
            toLanguageDropdown.options.add(opt);
        });
    });
}

function swap(arr, from, to) {
    arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);
}

$('#fromLanguageDropdown').on('select2:select', function (e) {
    const data = e.params.data;
    if (data.id === toLanguageDropdownSelectedValue) {
        switchLanguages();
    } else {
        fromLanguageDropdownSelectedValue = data.id;
    }
});

$('#toLanguageDropdown').on('select2:select', function (e) {
    const data = e.params.data;
    if (data.id === fromLanguageDropdownSelectedValue) {
        switchLanguages();
    } else {
        toLanguageDropdownSelectedValue = data.id;
    }
});


function getVersion() {
    switch (version) {
        case 'Word': {
            return 'DOCX_PLUGIN';
            break;
        }
        case 'Excel': {
            return 'XLSX_PLUGIN';
            break;
        }
        case 'PowerPoint': {
            return 'PPTX_PLUGIN';
            break;
        }
        case 'Outlook': {
            return 'OUTLOOK_PLUGIN';
            break;
        }
        default: return 'DOCX_PLUGIN';
    }
}

function checkForExistingClientId(id, save = false, username = '') {
    if (!!id && id !== "" && id !== "null") {
        getFormDataLogin().then((response) => {
            urlCallPost(`${coreUrl}verify_credentials/`, response).then((response2) => {
                if (response2.hasOwnProperty("status") && response2.status === 'OK') {
                    if (!!save) {
                        localStorage.setItem(version + "cId", clientIdValue);
                    }
                    // Proceed to step 2; From Offline step -> Online Step
                    keyCheckStep.style.display = "none";
                    onlineLoginUpdate(true);
                    showHideLoadingSpinner(false);
                    SkipFileUpload();
                    existingClientIdRemoveFirstSteps();
                } else {
                    rejectClientIdUpload();
                }
            })
        })
    } else {
    }
}

function clientIdUpload() {
    showHideLoadingSpinner(true);
    clientUsernameValue = document.getElementsByTagName("input")[1]["value"];
    clientIdValue = document.getElementsByTagName("input")[0]["value"];
    clientProjectIdValue = document.getElementsByTagName("input")[2]["value"];
    const checkInput = document.getElementsByTagName("input")[3];
    if (clientIdValue.length < 8) {
        rejectClientIdUpload();
    } else if (checkInput.checked) {
        checkForExistingClientId(clientIdValue, true);
    } else {
        checkForExistingClientId(clientIdValue, false);
    }
}

function rejectClientIdUpload() {
    // popupClientIdReject.style.display = "block";
    showHideLoadingSpinner(false);
    clientIdRejectSpan.style.display = "block";
    setTimeout(() => {
        // popupClientIdReject.style.display = "none";
        clientIdRejectSpan.style.display = "none";
    }, 3000);
}

function clientIdResetF() {
    closeNav();
    onlineStep.style.display = "none";
    keyCheckStep.style.display = "block";
    localStorage.clear();
    onlineLoginUpdate(false);
    removeResultsSection();
    removeLanguages();
    removeLocalTranslations();
    stopInterval();
    changeFileUpload();
    resetLoginForm();
}

function resetLoginForm() {
    document.getElementsByTagName("input")[3]['checked'] = false;
    document.getElementsByTagName("input")[2]['value'] = '';
    document.getElementsByTagName("input")[1]['value'] = '';
    document.getElementsByTagName("input")[0]['value'] = '';
}


function SkipFileUpload() {
    onlineStep.style.display = "block";
    onlineStep.style.transition = "opacity 1s ease-in";
    subscribeToEvent();
    createDropdownLists();
    updateToolTipTexts(false);
    checkUploadFunctionalityState(false);
    arrowSwitchFunctionalityStyle(true);
}

async function tryCatch(callback) {
    try {
        await callback();
    } catch (error) {
        console.error(error);
    }
}

async function getFormData() {
    return new Promise((resolve) => {
        let formData = new URLSearchParams();
        let id = localStorage.getItem(version + "cId");
        if (!id || id === "null") {
            id = clientIdValue;
        }
        if (clientProjectIdValue !== '') {
            formData.append("project_id", clientProjectIdValue);
        }
        formData.append("credential", id);
        formData.append("from", fromLanguageDropdownSelectedValue);
        formData.append("to", toLanguageDropdownSelectedValue);
        formData.append("input", currentText);
        formData.append("source_type", getVersion())
        resolve(formData);
    });
}

async function getFormDataLogin() {
    return new Promise((resolve) => {
        let formData = new URLSearchParams();
        let id = localStorage.getItem(version + "cId");
        if (!id || id === "null") {
            id = clientIdValue;
        }
        formData.append("credential", id);
        if (clientUsernameValue !== '') {
            formData.append("username", clientUsernameValue);
        }
        if (clientProjectIdValue !== '') {
            formData.append("project_id", clientProjectIdValue);
        }
        resolve(formData);
    });
}

async function runOnline() {
    if (version === 'Word') {
        await Word.run(async (context) => {
            const range = context.document.getSelection();
            range.load("text");
            await contextSync(context, range);
        });
    } else if (version === 'Excel') {
        await Excel.run(async (context) => {
            const range = context.workbook.getSelectedRange();
            range.load("text");
            await contextSync(context, range);
        });
    } else if (version === 'PowerPoint') {
        powerPointContextSync();
    } else if (version === 'Outlook') {
        OutlookContextSync();
    }
}

async function contextSync(context, range) {
    return await context.sync().then(() => {
        context.trackedObjects.add(range);
        const _text = version === 'Word' ? range.text : version === 'Excel' ? range.text[0][0] : range.text;
        if (_text.trim() !== "") {
            currentText = _text.trim();
            const cId = localStorage.getItem(version + "cId");
            // ONLINE SEARCH
            if ((clientIdValue || !!cId) && isOnlineLogin) {
                showHideLoadingSpinner(true);
                getFormData().then(response => {
                    urlCallPost(coreUrl, response).then((response) => {
                        showHideLoadingSpinner(false);
                        if (response.hasOwnProperty("error_msg")) {
                            popupAlert(range, response);
                        } else {
                            // popupConfirm(range, response);
                            resultsConfirm(range, response);
                        }
                    });
                });
            } else {
                // OFFLINE SEARCH
                offlineResultsConfirm(range);
            }
        } else {
            console.log('Error: Please select a text first');
            // popupAlert(range, 'This is needed')
            noTextSelected.style.display = "block";
            popupClose();
        }
    });
}

function powerPointContextSync() {
    const options = { coercionType: Office.CoercionType.Text };
    Office.context.document.getSelectedDataAsync(Office.CoercionType.Text, function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            console.log('Error: Please select a text first');
            noTextSelected.style.display = "block";
            popupClose();
        } else {
            console.log(30, asyncResult.value);
            if (asyncResult && asyncResult.value !== '') {
                currentText = asyncResult.value.trim();
                const cId = localStorage.getItem(version + "cId");
                if ((clientIdValue || !!cId) && isOnlineLogin) {
                    showHideLoadingSpinner(true);
                    getFormData().then(response => {
                        urlCallPost(coreUrl, response).then((response) => {
                            showHideLoadingSpinner(false);
                            if (response.hasOwnProperty("error_msg")) {
                                popupAlert({}, response);
                            } else {
                                resultsConfirm(undefined, response);
                            }
                        });
                    });
                } else {
                    // OFFLINE SEARCH
                    offlineResultsConfirm(undefined);
                }
            }
        }
    });
}

function OutlookContextSync() {
    const item = Office.context.mailbox.item;
    item.body.getAsync(Office.CoercionType.Html, (bodyResult) => {
        if (bodyResult.status === Office.AsyncResultStatus.Succeeded) {
            // Office.context.mailbox.item.body.prependAsync(bodyResult.value, { coercionType: Office.CoercionType.Html}, (asyncResult) => {
            //     if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            //       console.log("Action failed with error: " + asyncResult.error.message);
            //       return;
            //     }

            //     console.log(`"${bodyResult.value}" prepended to the body.`);
            //   });

            //   Office.context.mailbox.item.body.setAsync(
            //     "<b>(replaces all body, including threads you are replying to that may be on the bottom)</b>",
            //     { coercionType: "html", asyncContext: "This is passed to the callback" },
            //     function callback(result) {
            //         // Process the result.
            // });

            currentText = bodyResult.value;
            showHideLoadingSpinner(true);
            getFormData().then(response => {
                urlCallPost(coreUrl, response).then((response) => {
                    showHideLoadingSpinner(false);
                    if (response.hasOwnProperty("error_msg")) {
                        popupAlert({}, response);
                    } else {
                        outlookResultsConfirm(response);
                        // resultsConfirm(undefined, response);
                    }
                });
            });
        } else {
            console.log('ERROR');
        }
    })
}

function resultsConfirm(range, response) {
    if (!!range) {
        currentRange = range;
    }
    resultsSection.style.display = "block";
    if (response.hasOwnProperty("translation") && response.translation.error_msg === null) {
        const _value = response.translation.translation.target_text || response.translation.translation;
        const container = $('#resultsData');
        container.empty();
        $('<p/>').appendTo(container).append(
            $('<input>').prop({
                type: 'radio',
                id: _value,
                name: 'translation',
                value: _value
            })
        ).append(
            $('<label>').prop({
                for: _value
            }).html(_value)
        );
    }

}

function outlookResultsConfirm(response) {
    resultsSection.style.display = "block";
    if (response.hasOwnProperty("translation") && response.translation.error_msg === null) {
        const _value = response.translation.translation.target_text || response.translation.translation;
        const container = $('#resultsData');
        container.empty();
        $('<p/>').appendTo(container).append(_value)
    }
}

function removeResultsSection() {
    if (resultsSection) {
        resultsSection.style.display = "none";
    }
    $('#resultsData').empty()
}

function removeLanguages() {
    fromLanguageDropdown.options.length = 0;
    toLanguageDropdown.options.length = 0;
}

function clickButtonReplace() {
    const checked = document.querySelector("input[type='radio'][name=translation]:checked");
    if (checked && !!checked) {
        const _value = checked.value;
        if (version === 'Word') {
            currentRange.insertText(_value.toString(), Word.InsertLocation.replace);
        } else if (version === 'Excel') {
            currentRange.values = [[_value.toString()]];
            currentRange.format.autofitColumns();
        } else if (version === 'PowerPoint') {
            const options = { coercionType: Office.CoercionType.Text };
            Office.context.document.setSelectedDataAsync(" ", options);
            Office.context.document.setSelectedDataAsync(_value.toString(), options);
        } else if (version === 'Outlook') {
            // Empty for now
        }
        if (!!currentRange) {
            currentRange.select();
            currentRange.context.trackedObjects.remove(currentRange);
            currentRange.context.sync();
        }
        removeResultsSection();
    } else {
        console.log('Please select an option.');
        noOptionSelected.style.display = "block";
    }
}

function clickButtonCancel() {
    if (version === 'Word') {
        removeSelectionWord();
    } else if (version === 'Excel') {
        removeSelectionExcel();
    }
    removeResultsSection();
}

function popupConfirm(range, dataFromUrl) {
    popupApprove.style.display = "block";
    popupApprove.childNodes.forEach((child) => {
        if (child.childNodes.length > 0) {
            child.childNodes.forEach((c) => {
                if (c.childNodes.length > 0) {
                    c.childNodes.forEach((c0) => {
                        if (c0.childNodes.length > 0) {
                            c0.childNodes.forEach((c0) => {
                                if (c0.nodeName === "H3") {
                                    c0.textContent = "We found some possible translations...";
                                }
                            });
                        }
                        if (c0.nodeName === "H2") {
                            c0.textContent = "We found some possible translations...";
                        }
                        if (c0.nodeName === "H3") {
                            c0.textContent = `Selected text: ${range.text}`;
                        }
                        if (c0.nodeName === "P") {
                            let sp1 = document.createElement("P");
                            if (dataFromUrl.hasOwnProperty("translation") && dataFromUrl.translation.error_msg === null) {
                                const btn = document.createElement("button");
                                const t = document.createTextNode(dataFromUrl.translation.translation.target_text);
                                const translation = dataFromUrl.translation.translation.target_text;
                                btn.addEventListener(
                                    "click",
                                    function (e) {
                                        e.preventDefault();
                                        clickButton(translation, range);
                                        return false;
                                    },
                                    false
                                );
                                btn.appendChild(t);
                                sp1.appendChild(btn);
                                c0.replaceWith(sp1);
                            }
                        }
                    });
                }
            });
        }
    });
}

function clickButton(value, range) {
    if (version === 'Word') {
        range.insertText(value.toString(), Word.InsertLocation.replace);
    } else if (version === 'Excel') {
        currentRange.values = [[value.toString()]];
        currentRange.format.autofitColumns();
    } else if (version === 'PowerPoint') {
        const options = { coercionType: Office.CoercionType.Text };
        Office.context.document.setSelectedDataAsync(" ", options);
        Office.context.document.setSelectedDataAsync(value.toString(), options);
    } else if (version === 'Outlook') {
        // Empty for now
    }
    range.select();
    range.context.trackedObjects.remove(range);
    range.context.sync();
    popupClose();
}

function popupClose() {
    //1. De-select the selected text
    if (version === 'Word') {
        removeSelectionWord();
    } else if (version === 'Excel') {
        removeSelectionExcel();
    }
    //2. De-select the selected text
    popupApprove.style.display = "none";
}

function popupAlert(range, response) {
    popupReject.style.display = "block";
    popupReject.childNodes.forEach((child) => {
        if (child.childNodes.length > 0) {
            child.childNodes.forEach((c) => {
                if (c.childNodes.length > 0) {
                    c.childNodes.forEach((c0) => {
                        if (c0.nodeName === "H3") {
                            c0.textContent = response.error_msg;
                        }
                        if (c0.nodeName === "P") {
                            c0.textContent = "Please change the input credentials, thank you.";
                        }
                        if (c0.childNodes.length > 0) {
                            c0.childNodes.forEach((c1) => {
                                if (c1.nodeName === "H2") {
                                    c1.textContent = "Error in the request.";
                                }
                            });
                        }
                    });
                }
            });
        }
    });
}

function stopInterval() {
    clearInterval(int);
}

function subscribeToEvent() {
    // Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, runOnline);
}

function unsubscribeToEvent() {
    // Office.context.document.removeHandlerAsync(Office.EventType.DocumentSelectionChanged, runOnline);
}

async function urlCallPost(url, params) {
    return new Promise((resolve) => {
        const http = new XMLHttpRequest();
        http.open("POST", url, true);
        http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        http.onreadystatechange = function () {
            if (http.responseText !== '') {
                resolve(JSON.parse(http.responseText));
            }
        };
        http.send(params);
    });
}

async function urlCallGet(url) {
    return new Promise((resolve) => {
        const http = new XMLHttpRequest();
        http.open("GET", url, true);
        http.setRequestHeader("Content-type", "application/json");
        http.onreadystatechange = function () {
            if (http.responseText !== '') {
                const response = typeof http.responseText === 'string' ? JSON.parse(http.responseText) : http.responseText;
                resolve(response);
            }
        };
        http.send({});
    });
}

function checkUploadFunctionalityState(state) {
    $("#OpenFileUpload").prop('disabled', !state).removeClass(!state ? 'enabled' : 'disabled').addClass(state ? 'enabled' : 'disabled');
}

function activateOfflineStep() {
    onlineStep.style.display = "block";
    keyCheckStep.style.display = "none";
    localStorage.setItem(version + "cId", null);
    stopInterval();
    updateToolTipTexts(true);
    checkUploadFunctionalityState(true);
    arrowSwitchFunctionalityStyle(false);
}

function arrowSwitchFunctionalityStyle(show) {
    if (show) {
        arrowSwitch.style.visibility = "visible";
    } else {
        arrowSwitch.style.visibility = "hidden";
    }
}

function switchLanguages() {
    const tmp = fromLanguageDropdownSelectedValue;
    fromLanguageDropdownSelectedValue = toLanguageDropdownSelectedValue;
    toLanguageDropdownSelectedValue = tmp;
    setTimeout(() => createDropdownListsSwitch(), 10);
}

function createDropdownListsSwitch() {
    let fromObj = languagesArray.find(l => l.language === fromLanguageDropdownSelectedValue);
    let toObj = languagesArray.find(l => l.language === toLanguageDropdownSelectedValue);
    if (fromObj && toObj) {
        fromLanguageDropdown.options.length = 0;
        let fromOpt = document.createElement("option");
        fromOpt.text = fromObj.name;
        fromOpt.value = fromObj.language;
        fromLanguageDropdown.options.add(fromOpt);
        languagesArray.forEach((language) => {
            if (language.language !== fromObj.language) {
                let opt = document.createElement("option");
                opt.text = language.name;
                opt.value = language.language;
                fromLanguageDropdown.options.add(opt);
            }
        });
        toLanguageDropdown.options.length = 0;
        let toOption = document.createElement("option");
        toOption.text = toObj.name;
        toOption.value = toObj.language;
        toLanguageDropdown.options.add(toOption);
        languagesArray.forEach((language) => {
            if (language.language !== toObj.language) {
                let opt = document.createElement("option");
                opt.text = language.name;
                opt.value = language.language;
                toLanguageDropdown.options.add(opt);
            }
        });
    }
}

function removeSelectionWord() {
    Word.run(async (context) => {
        const range = context.document.getSelection();
        range.select("End");
        return context.sync();
    }).then(r => r);
}

function removeSelectionExcel() {
    Excel.run(async (context) => {
        const range = context.workbook.getSelectedRange();
        range.select()
        return context.sync();
    }).then(r => r);
}

// EXTRA STEPS FOR PUBLISH

function startSecondStep() {
    introStepOne.style.display = "none";
    if (version === 'Outlook') {
        startLoginStep();
    } else {
        introStepTwo.style.display = "block";
    }
}

function startLoginStep() {
    introStepOne.style.display = "none";
    introStepTwo.style.display = "none";
    keyCheckStep.style.display = "block";
}

function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
}

function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
}

function onlineLoginUpdate(update = true) {
    isOnlineLogin = update;
    if (update === true) {
        logoutAnchor.style.display = "block";
    } else {
        logoutAnchor.style.display = "none";
    }
}

function changeFileUpload() {
    if (version !== Office.HostType.Outlook) {
        referenceSection.style.display = "block";
    }
    referenceTitleSection.style.display = "none";
}

function existingClientIdRemoveFirstSteps() {
    introStepOne.style.display = "none";
    introStepTwo.style.display = "none";
    keyCheckStep.style.display = "none";
}